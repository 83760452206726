@media only screen and (max-width: 991px)  {
    .searchBox {width: 400px;}
}
@media only screen and (max-width: 767px)  {
    .searchBoxWrapper .logoicon {
        width: 150px;
    }
    .searchBoxWrapperMain {width: 97%;}
}
@media only screen and (max-width: 700px) {
    .searchBox {
        width: 60%;
    }
}
@media only screen and (max-width: 640px)  {
    .header_section .logo {width: 270px;}
    .vehicle_empty_list img {
        width: 70px !important;
    }
    .vehicle_empty_list h3 {
        font-size: 14px;
    }
}
@media only screen and (max-width: 600px)  {
    .section_wraps .btn{width: 170px;}
    .header_section {margin: 0px 0 35px;}
    .landmark-details-wrapper.active {width: 96%;transform: translateX(2%);}
    .landmark-details-wrapper {width: 96%;}
    .respohidelogo {display: none;}
    .searchBox {width: 100%;}
    .searchBoxWrapper {padding: 8px 10px 8px 10px;}
    .clearbtn {right: 61px;top: 12px;}
    .clearbtn img {
        width: 16px !important;
    }
    .footer_section .btn img {
        width: 120px;
    }
}
@media only screen and (max-width: 540px)  {
    .searchBoxWrapperMain .backbtn {height: 45px;width: 45px;}
    .searchBox input {font-size: 12px;}
    .searchBoxWrapper {padding: 5px 5px 5px 5px;}
    .searchBoxWrapperMain {column-gap: 9px;}
    .vehiclDetailsWrapper h4 {font-size: 15px;}
    .vehiclDetailsWrapper .vehicle_details_wraps .column_details label {font-size: 12px;}
}
@media only screen and (max-width: 400px)  {
    .section_wraps { flex-direction: column;row-gap: 20px}
    .clearbtn {right: 49px;}
    .searchBox .btnsearchbX {padding: 8px 10px;height: 40px;width: 39px;}
}