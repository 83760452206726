* {box-sizing: border-box;padding: 0;margin: 0;}
body {box-sizing: border-box;position: relative;}
.wrapper {float: left;width: 100%;overflow: hidden;}

/* main css start here */
.main_wrapper {float: left;width: 100%;position: relative;height: 100vh;display: flex;align-items: center;justify-content: center;flex-direction: column;background-image: url("../img/login-bg.jpg");background-repeat: no-repeat;background-size: cover;background-position: top;position: relative;}
.main_wrapper::before {content: "";float: left;width: 100%;height: 100%;background: rgba(255, 255, 255, 0.9);position: absolute;left: 0;right: 0;-webkit-backdrop-filter: blur(7px);backdrop-filter: blur(7px);border-radius: 0px;margin: 0 auto;top: 0px;}
.header_section {float: left;width: 100%;text-align: center;margin: 0 0 65px;position: relative;}
.header_section .logo {width: 380px;}
.panelContentwrapper {float: left;width: 100%;position: relative;}
.section_wraps {float: left;width: 100%;display: flex;justify-content: center;align-items: center;column-gap: 20px;}
.section_wraps .btn {padding: 0px;display: flex;justify-content: center;flex-direction: column;align-items: center;row-gap: 12px;width: 260px;outline: none !important;border: 0px !important;}
.section_wraps .btn img {width: 100%;}
.body_wrapper {float: left;width: 100%;position: relative;padding: 20px 20px;}
/* main css end here */
.serachMapLocation {float: left;width: 100%;position: relative;}
.searchBoxWrapperMain {position: fixed;z-index: 999;left: 0;right: 0;top: 15px;float: none;width: 95%;margin: 0 auto;display: flex;justify-content: space-between;align-items: center;column-gap: 15px;}
.searchBoxWrapper {width: 100%;display: flex;align-items: center;justify-content: space-between;padding: 8px 10px 8px 25px;border-radius: 30px;background-color: rgba(255,255,255,0.9);backdrop-filter: blur(2px);box-shadow: 6px 3px 19px -9px #333;}
.searchBoxWrapperMain .backbtn {background: linear-gradient(171deg, #f1b94c, #d48d04 70px) !important;border-radius: 100%;padding: 16px 13px;height: 50px;width: 50px;box-shadow: 6px 3px 19px -9px #333;border: 0px !important;}
.searchBoxWrapperMain .backbtn img {float: left;width: 100%;height: 100%;}
.searchBoxWrapper .logoicon {width: 210px;}
.searchBox {float: left;width: 40%;position: relative;height: 40px;}
.searchBox input {float: left;width: 100%;height: 100%;border-radius: 30px;box-shadow: none;padding: 7px 45px 7px 20px;font-size: 14px;border: 1px solid #a3a3a3;outline: none !important;box-shadow: none !important;}
.searchBox .btnsearchbX {background: linear-gradient(171deg, #f1b94c, #d48d04 70px) !important;border-radius: 10px 30px 30px 10px;padding: 8px 2px;position: absolute;height: 40px;width: 50px;right: 1px;top: 0px;border: 0px !important;}
.searchBox img {float: left;width: 100%;height: 100%;object-fit: contain;object-position: center;}
.freeGoggleMap {float: left;width: 100%;height: 100vh;}
.landmark-details-wrapper {float: left;width: 370px;height: calc(100vh - 100px);background-color: #fff;box-shadow: 6px 3px 19px -9px #333;position: fixed;top: 85px;border-radius: 30px;transform: translateX(-110%);transition: 0.5s ease;z-index: 9;overflow: hidden;-webkit-transition: 0.5s ease;-moz-transition: 0.5s ease;-o-transition: 0.5s ease;-ms-transition: 0.5s ease;transition: 0.5s ease;}
.landmark-details-wrapper.active {transform: translateX(11%);-webkit-transition: 0.5s ease;-moz-transition: 0.5s ease;-o-transition: 0.5s ease;-ms-transition: 0.5s ease;transition: 0.5s ease;    }
.landmarkImage {float: left;width: 100%;height: 180px;position: relative;overflow: hidden;}
.landmarkImage .landmark_background {width: 100%;height: 100%;object-fit: cover;object-position: top;}
.landmark_wrappps {float: left;width: 100%;position: relative;height: 291px;overflow: auto;}
.landmark_wrappps h1 {float: left;width: 100%;font-size: 18px;text-transform: capitalize;padding: 12px 15px;border-bottom: 1px solid #ccc;display: flex;justify-content: space-between;align-items: center;}
.landmark_wrappps ul {float: left;width: 100%;padding: 0px;}
.landmark_wrappps ul li {float: left;width: 100%;list-style: none;font-size: 14px;margin-bottom: 0;padding: 6px 15px;display: flex;text-align: left;}
.landmark_wrappps ul li img {float: left;width: 20px;height: 20px;object-fit: contain;object-position: left;margin-right: 15px;}
.landmark_wrappps ul li div {float: left;width: auto;}
.landmarkImage img.close_icon {height: 25px;width: 25px;position: absolute;right: 14px;top: 14px;cursor: pointer;background-color: #d95e36;border-radius: 100%;padding: 4px;line-height: 25px;}
.checkTreatment_sectin {float: left;width: 100%;position: relative;height: calc(100vh - 85px);margin-top: 85px;overflow: auto;}
.checkTreatment_sectin .container {max-width: 1255px;}
.vehiclDetailsWrapper {float: left;width: 100%;box-shadow: 6px 3px 19px -9px #333;padding: 10px 10px;border-radius: 30px;margin-bottom: 25px;}
.vehiclDetailsWrapper .vehicle_details_wraps {float: left;width: 100%;padding: 5px 0px;margin: 0px;}
.vehiclDetailsWrapper .vehicle_details_wraps .row {margin: 0px;}
.vehiclDetailsWrapper .vehicle_details_wraps .column_details {float: left;width: 50%;list-style: none;font-size: 13px;padding: 6px 10px;border-bottom: 1px solid #eee;}
.vehiclDetailsWrapper .vehicle_details_wraps .column_details label {float: left;width: 100%;font-weight: 600;}
.vehiclDetailsWrapper h4 {float: left;width: 100%;font-size: 18px;margin: 7px 0 5px;padding: 0px 10px;color: #d48d04;}
.vehicle_details_wraps .row:last-child .column_details {border-bottom: 0px;}
.vehicle_empty_list {float: left;width: 100%;display: flex;justify-content: center;flex-direction: column;align-items: center;row-gap: 15px;height: calc(100vh - 95px);}
.vehicle_empty_list img {width: 65px;}
.vehicle_empty_list h3 {margin: 0 0 0;font-size: 19px;}
.vehicle_empty_list .loaderaractive {animation: spin 2s linear infinite;}
.clearbtn {background: transparent;border: 0px;position: absolute;right: 60px;top: 10px;z-index: 9;}
.clearbtn img {width: 20px !important;height: auto;}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.serachLOcatinWrapper {
    float: left;
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    padding: 15px 15px;
}
.searchWrpps {
    float: left;
    width: 100%;
    margin: 0 0 10px;
    position: relative;
}
.searchWrpps .form-control {
    height: 43px;
    box-shadow: none;
    border-color: #ddd;
    padding: 11px 11px;
    font-size: 14px;
}
.searchWrppsBox {
    float: left;
    width: 100%;
    padding: 11px 11px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 14px;
}
.direction_icon {
    height: 35px;
    width: 35px;
    float: right;
    border: 1px solid #ddd;
    border-radius: 100%;
    padding: 6px;
    cursor: pointer;
}

.direction_icon img {
    float: left;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}
.landmark_wrappps h1 span {
    float: left;
    width: 87%;
    word-break: break-word;
}

/* loader css */
.CommonLoader {
    float: left;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 99;
}
.CommonLoader img {
    width: 100px;
}
.CommonLoader span {
    font-size: 14px;
    color: #818181;
}
/* loader css */

/* footer link section */
.footer_section {
    float: left;
    width: 100%;
    z-index: 9;
    display: flex;
    justify-content: center;
    margin: 30px 0 0;
    align-items: center;
    column-gap: 15px;
}
.footer_section .btn {
    padding: 0px;
}
.footer_section .btn img {
    width: 180px;
}
/* footer link section */